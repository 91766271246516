@-webkit-keyframes animBefore {
    0% {
        -webkit-transform: scale(1) translateY(-200%);
        z-index: 10;
    }
    25% {
        -webkit-transform: scale(1.3) translateY(0);
        z-index: 10;
    }
    50% {
        -webkit-transform: scale(1) translateY(200%);
        z-index: 9;
    }
    75% {
        -webkit-transform: scale(0.7) translateY(0);
        z-index: 9;
    }
    100% {
        -webkit-transform: scale(1) translateY(-200%);
        z-index: 9;
    }
}
@keyframes animBefore {
    0% {
        transform: scale(1) translateY(-200%);
        z-index: 10;
    }
    25% {
        transform: scale(1.3) translateY(0);
        z-index: 10;
    }
    50% {
        transform: scale(1) translateY(200%);
        z-index: 9;
    }
    75% {
        transform: scale(0.7) translateY(0);
        z-index: 9;
    }
    100% {
        transform: scale(1) translateY(-200%);
        z-index: 9;
    }
}
@-webkit-keyframes animAfter {
    0% {
        -webkit-transform: scale(1) translateY(200%);
        z-index: 9;
    }
    25% {
        -webkit-transform: scale(0.7) translateY(0);
        z-index: 9;
    }
    50% {
        -webkit-transform: scale(1) translateY(-200%);
        z-index: 10;
    }
    75% {
        -webkit-transform: scale(1.3) translateY(0);
        z-index: 10;
    }
    100% {
        -webkit-transform: scale(1) translateY(200%);
        z-index: 10;
    }
}
@keyframes animAfter {
    0% {
        transform: scale(1) translateY(200%);
        z-index: 9;
    }
    25% {
        transform: scale(0.7) translateY(0);
        z-index: 9;
    }
    50% {
        transform: scale(1) translateY(-200%);
        z-index: 10;
    }
    75% {
        transform: scale(1.3) translateY(0);
        z-index: 10;
    }
    100% {
        transform: scale(1) translateY(200%);
        z-index: 10;
    }
}


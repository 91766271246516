@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes animBefore {
    0% {
        -webkit-transform: scale(1) translateY(-200%);
        z-index: 10;
    }
    25% {
        -webkit-transform: scale(1.3) translateY(0);
        z-index: 10;
    }
    50% {
        -webkit-transform: scale(1) translateY(200%);
        z-index: 9;
    }
    75% {
        -webkit-transform: scale(0.7) translateY(0);
        z-index: 9;
    }
    100% {
        -webkit-transform: scale(1) translateY(-200%);
        z-index: 9;
    }
}
@keyframes animBefore {
    0% {
        transform: scale(1) translateY(-200%);
        z-index: 10;
    }
    25% {
        transform: scale(1.3) translateY(0);
        z-index: 10;
    }
    50% {
        transform: scale(1) translateY(200%);
        z-index: 9;
    }
    75% {
        transform: scale(0.7) translateY(0);
        z-index: 9;
    }
    100% {
        transform: scale(1) translateY(-200%);
        z-index: 9;
    }
}
@-webkit-keyframes animAfter {
    0% {
        -webkit-transform: scale(1) translateY(200%);
        z-index: 9;
    }
    25% {
        -webkit-transform: scale(0.7) translateY(0);
        z-index: 9;
    }
    50% {
        -webkit-transform: scale(1) translateY(-200%);
        z-index: 10;
    }
    75% {
        -webkit-transform: scale(1.3) translateY(0);
        z-index: 10;
    }
    100% {
        -webkit-transform: scale(1) translateY(200%);
        z-index: 10;
    }
}
@keyframes animAfter {
    0% {
        transform: scale(1) translateY(200%);
        z-index: 9;
    }
    25% {
        transform: scale(0.7) translateY(0);
        z-index: 9;
    }
    50% {
        transform: scale(1) translateY(-200%);
        z-index: 10;
    }
    75% {
        transform: scale(1.3) translateY(0);
        z-index: 10;
    }
    100% {
        transform: scale(1) translateY(200%);
        z-index: 10;
    }
}


/* outer level viewer container */
.la-vz-seqviz {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
}

.la-vz-seqviz svg {
  overflow: visible;
}

.la-vz-seqviz svg text {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  fill: #2a2a2a;
  font-family: Roboto Mono, Monaco, monospace;
}

.la-vz-seqviz svg text:selection {
  background: none;
}

.la-vz-viewer-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.la-vz-viewer-event-router {
  position: absolute;
  outline: none;
  height: 100%;
  width: inherit;
}

/* circular viewer */
.la-vz-circular-viewer {
  margin: auto;
  font-size: 13px;
  font-weight: 400;
}

.la-vz-circular-bps {
  cursor: text;
}

.la-vz-circular-label {
  cursor: pointer;
}

.la-vz-circular-label:hover {
  text-decoration: underline;
}

.la-vz-label-line {
  stroke: #9eaab8;
  stroke-width: 1;
  fill: none;
}

/* linear viewer */
.la-vz-linear-scroller {
  height: 100%;

  outline: none !important;
  position: relative;
  font-weight: 300;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  cursor: text;
}

.la-vz-seqblock-container {
  width: 100%;
}

.la-vz-seqblock {
  width: 100%;
  padding: 0;
  overflow: visible;
}

.la-vz-cut-site-text {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
}

.la-vz-linear-sel-block {
  fill: #def6ff;
}

.la-vz-scroll::-webkit-scrollbar {
  width: 7px;
}

.la-vz-scroll::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 7px;
  background-color: #a6a6a6;
}

.la-vz-scroll::-webkit-scrollbar-track {
  border-radius: 20px;
}

.la-vz-scroll::-webkit-scrollbar-track-piece {
  background-clip: padding-box;
  border-radius: 7px;
  background-color: #ececec;
}

.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.NavBar-logo {
  height: 64px;
  pointer-events: none;
}

.container-fluid {
  max-width: 1500px;
}

table {
  table-layout: fixed;
}

table > tbody > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  text-align: center;
}

table > thead > tr > th {
  text-align: center;
}

body {
  background-color: rgb(248,249,250);
}

.popover {
  max-width: 1000px;
}

.popover-body {
  max-height: 35vh;
  overflow-y: auto;
}

.searchbar{
  margin-bottom: auto;
  margin-top: auto;
  height: 40px;
  border: 1px solid #353b48;
  border-radius: 30px;
  padding: 10px;
}

.search_input{
  color: #353b48;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  padding: 0;
  caret-color:transparent;
  line-height: 20px;
  transition: width 0.4s linear;
}

.searchbar:hover > .search_input{
  padding: 0 10px;
  width: 250px;
  caret-color:white;
  transition: width 0.4s linear;
}

.search_icon{
  height: 20px;
  width: 20px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color:black;
  background: white;
  text-decoration:none;
}

.st0{fill:#DFD399;}
.st1{opacity:0.05;}
.st2{fill:#E2E7E9;}
.st3{fill:none;stroke:#F19737;stroke-width:3.75;stroke-miterlimit:10;}
.st4{fill:none;stroke:#F19737;stroke-width:2;stroke-miterlimit:10;}
.st5{font-family:'Courier', monospace;}
.st6{font-size:12px;}
.st7{font-size:18px;}
.st8{fill:none;stroke:#000000;stroke-miterlimit:10;}
.st9{stroke:#000000;stroke-miterlimit:10;}
.st10{fill:none;}
.st11{fill:#F19737;}
.st12{font-family:'Courier', monospace;font-weight: bold}
